import React from "react"
import { Link, navigate } from "gatsby"
// import styled from 'styled-components';

// const PaginationStyles = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-items: center;
//   border: 1px solid var(--grey);
//   margin: 2rem 0;
//   border-radius: 5px;
//   text-align: center;
//   & > * {
//     padding: 1rem;
//     flex: 1;
//     border-right: 1px solid var(--grey);
//     text-decoration: none;
//     &[aria-current],
//     &.current {
//       color: var(--red);
//     }
//     &[disabled] {
//       pointer-events: none;
//       color: var(--grey);
//     }
//   }
//   @media (max-width: 800px) {
//     .word {
//       display: none;
//     }
//     font-size: 1.4rem;
//   }
// `;

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}) {
  // make some variables
  const totalPages = Math.ceil(totalCount / pageSize)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const hasNextPage = nextPage <= totalPages
  const hasPrevPage = prevPage >= 1
  return (
    <div className="flex items-center">
      <button
        className="py-[14px] px-4 border border-[#EAE9F2] bg-white rounded-md disabled:cursor-not-allowed"
        onClick={() => {
          navigate(`${base}/${prevPage}`)
        }}
        disabled={!hasPrevPage}
      >
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 2L2 7L7 12"
            stroke="#1D263A"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      </button>

      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          className={
            currentPage === 1 && i === 0
              ? "pt-[5px] pb-[1px] text-[21px] px-4 border border-[#EAE9F2] bg-[#E94C28] text-white rounded-md mx-3"
              : "pt-[5px] pb-[1px] text-[21px] px-4 border border-[#EAE9F2] bg-white  rounded-md mx-3"
          }
          to={`${base}/${i > 0 ? i + 1 : ""}`}
          key={`page${i}`}
          activeStyle={{
            background: "#E94C28",
            color: "white",
          }}
        >
          {i + 1}
        </Link>
      ))}
      <button
        className="py-[14px] px-4 border border-[#EAE9F2] bg-white rounded-md disabled:cursor-not-allowed"
        onClick={() => {
          navigate(`${base}/${nextPage}`)
        }}
        disabled={!hasNextPage}
      >
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L7 7L2 12"
            stroke="#1D263A"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      </button>
    </div>
  )
}
